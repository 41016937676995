import React from "react"

import Row from "../../../components/Row/Row"
import Column from "../../../components/Column/Column"

import StepOne from "@src/icons/v2/affiliate-step-1.svg"
import StepTwo from "@src/icons/v2/affiliate-step-2.svg"
import StepThree from "@src/icons/v2/affiliate-step-3.svg"
import StepFour from "@src/icons/v2/affiliate-step-4.svg"
import * as styles from "./Steps.module.scss"

const Steps = () => {
  return (
    <section>
      <Row className={styles.stepsCards}>
        <h2 className={styles.stepsCardsTitle}>
          Take a few simple steps for many gains
        </h2>
        <div className={styles.stepsCardsText}>
          <Column.Wrap numPerRow={4}>
            <Column
              title="Start easy, stay breezy"
              icon={() => <img src={StepOne} />}
              description={
                "Fill out our simple registration form to become a Canonic Affiliate."
              }
            />
            <Column
              title="Get as social as you can"
              icon={() => <img src={StepTwo} />}
              description="Utilize your unique referral link to share Canonic with your audience."
            />
            <Column
              title="Enjoy fruits of your hard work"
              icon={() => <img src={StepThree} />}
              description={
                "Earn a generous 20% commission for every referral's license revenue."
              }
            />
            <Column
              title="We are 100% with you"
              icon={() => <img src={StepFour} />}
              description={
                "Get personalised assistance, regular updates, and promotional materials."
              }
            />
          </Column.Wrap>
        </div>
      </Row>
    </section>
  )
}

export default Steps
