// extracted by mini-css-extract-plugin
export var blue = "Text-module--blue--1sJWg";
export var darkBlueGradient = "Text-module--darkBlueGradient--1K6Cx";
export var grey = "Text-module--grey--1w7fJ";
export var h1 = "Text-module--h1--3gvIz";
export var h2 = "Text-module--h2--2EMjI";
export var h3 = "Text-module--h3--9njH_";
export var h4 = "Text-module--h4--2Ow7m";
export var h5 = "Text-module--h5--2vBQP";
export var h6 = "Text-module--h6--CFLpu";
export var heading = "Text-module--heading--14oJ9";
export var lightBlueGradient = "Text-module--lightBlueGradient--3uRuy";
export var noMargin = "Text-module--noMargin--1W7lO";
export var p = "Text-module--p--XRs2N";
export var showIn = "Text-module--show-in--eC7QG";
export var subtitle = "Text-module--subtitle--vDQeJ";
export var white = "Text-module--white--1Itfe";
export var whiteTitle = "Text-module--whiteTitle--2m518";