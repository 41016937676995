import React from "react"
import Row from "../../components/Row"
import DotBG from "../../../components/DotBG/DotBG"
import * as styles from "./Refer.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Refer = () => {
  const breakpoints = useBreakpoint()
  const appUrl = "https://forms.gle/HtzCAaxaTznEjTQW9"
  return (
    <Row className={styles.row}>
      <section className={styles.splash}>
        <div className={styles.splashText}>
          <h1 className={styles.splashTextTitle}>Ready to Refer and Earn?</h1>
          <div className={styles.splashTextCtas}>
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <button className={styles.splashTextCtasButton}>Apply Now</button>
            </a>
          </div>
        </div>
      </section>
    </Row>
  )
}

export default Refer
