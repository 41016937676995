// extracted by mini-css-extract-plugin
export var row = "Splash-module--row--15UMx";
export var splash = "Splash-module--splash--1Gc78";
export var splashIllustration = "Splash-module--splashIllustration--w2-0y";
export var splashText = "Splash-module--splashText--VYr96";
export var splashTextCtas = "Splash-module--splashTextCtas--2n7yS";
export var splashTextCtasButton = "Splash-module--splashTextCtasButton--198l0";
export var splashTextPromo = "Splash-module--splashTextPromo--2_wIt";
export var splashTextSubtitle = "Splash-module--splashTextSubtitle--2WZ8r";
export var splashTextTitle = "Splash-module--splashTextTitle--3YDX0";
export var splashTextTitleSpan = "Splash-module--splashTextTitleSpan--1kKJO";