import { string } from "prop-types"
import Text from "@src/components/Text"
import React from "react"

import * as styles from "./FAQ.module.scss"

function FAQ({ faq, index, toggleFAQ }) {
  const faqOpenClassname = faq.open ? styles.open : ""
  return (
    <article
      className={`${styles.faqsFaq} ${faqOpenClassname}`}
      key={index}
      onClick={() =>
        typeof toggleFAQ === "function" ? toggleFAQ(index) : null
      }
    >
      <Text.H4 className={styles.faqsFaqQuestion}>
        <strong>{faq.question}</strong>
      </Text.H4>
      <Text.P className={styles.faqsFaqAnswer}>{faq.answer}</Text.P>
    </article>
  )
}

export default FAQ
