import React from "react"
import Link from "gatsby-plugin-transition-link"
import getAppUrl from "@src/utils/getAppUrl"
import Row from "../../components/Row"
import * as styles from "./Splash.module.scss"

const Splash = () => {
  const appUrl = "https://forms.gle/HtzCAaxaTznEjTQW9"
  return (
    <Row className={styles.row}>
      <section className={styles.splash}>
        <div className={styles.splashText}>
          <h1 className={styles.splashTextTitle}>
            Join{" "}
            <span className={styles.splashTextTitleSpan}>
              Canonic’s affiliate program
            </span>{" "}
            to earn a 20% commission.
          </h1>
          <p className={styles.splashTextSubtitle}>
            Revolutionise how businesses automate tedious processes and unlock
            their full potential. Grow your network while earning a generous
            commission through our referral program.
          </p>
          <div className={styles.splashTextCtas}>
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <button className={styles.splashTextCtasButton}>Apply Now</button>
            </a>
          </div>
        </div>
      </section>
    </Row>
  )
}

export default Splash
