import React from "react"

import Row from "../../../components/Row/Row"
import Column from "../../../components/Column/Column"

import WhyOne from "@src/icons/v2/affiliate-why-1.svg"
import WhyTwo from "@src/icons/v2/affiliate-why-2.svg"
import WhyThree from "@src/icons/v2/affiliate-why-3.svg"
import WhyFour from "@src/icons/v2/affiliate-why-4.svg"
import * as styles from "./WhyCanonic.module.scss"

const WhyCanonic = () => {
  const appUrl = "https://forms.gle/HtzCAaxaTznEjTQW9"

  return (
    <section>
      <Row className={styles.whyCards}>
        <h2 className={styles.whyCardsTitle}>Wondering why Canonic?</h2>
        <div className={styles.whyCardsText}>
          <Column.Wrap numPerRow={2}>
            <Column
              title="Build Rapidly"
              icon={() => <img src={WhyOne} />}
              description="Get data from anywhere, structure the data your way, snap UI components instantly, and create workflows to reduce manual intervention"
            />
            <Column
              title="Scale without a sweat"
              icon={() => <img src={WhyTwo} />}
              description={
                "Add environments to manage deployments. Use code export for no vendor lock-in. Deploy on the cloud or on-premise. Provide role-based access as and when needed"
              }
            />
            <Column
              title="As flexible as it gets"
              icon={() => <img src={WhyThree} />}
              description={
                "Start with templates, use add-ons, and build with Canonic or Material UI component libraries. Add code where ever you need. Use just the frontend builder or the backend builder or both."
              }
            />
            <Column
              title="Save more than you invest"
              icon={() => <img src={WhyFour} />}
              description={
                "Build without much technical know-how. Automate to save time & developer resources. Focus completely on your business."
              }
            />
          </Column.Wrap>
          <div className={styles.whyCtas}>
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <button className={styles.whyCtasButton}>Apply Now</button>
            </a>
          </div>
        </div>
      </Row>
    </section>
  )
}

export default WhyCanonic
