import React from "react"
import * as styles from "./Row.module.scss"

const Row = ({ style, children, className = "", id }) => {
  return (
    <div style={style} className={`${styles.row} ${className}`} id={id}>
      <div className={styles.rowContent}>{children}</div>
    </div>
  )
}

export default Row
