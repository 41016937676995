import React from "react"

import Layout from "@src/components/Layout"
import Splash from "./sections/Splash"
import Steps from "./sections/Steps"
import WhyCanonic from "./sections/WhyCanonic/WhyCanonic"
import FAQs from "./sections/FAQs/FAQs"
import Refer from "./sections/Refer"
import ClientStrip from "../../V3/Home/sections/ClientStrip"

const AffiliateProgram = () => {
  return (
    <Layout
      title="Affiliate Program"
      description="Join us as we fundamentally change how we interact with the backend."
      url="https://canonic.dev/affiliate-program"
      //   ogImage={OG}
    >
      <Splash />
      <ClientStrip />
      <Steps />
      <WhyCanonic />
      <FAQs />
      <Refer />
    </Layout>
  )
}

export default AffiliateProgram
