import React from "react"
import * as styles from "./Text.module.scss"

const Text = {
  H1: ({ children, className, ...props }) => {
    return (
      <h1
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.h1} ${classNames(props)} ${className}`}
      >
        {children}
      </h1>
    )
  },
  H2: ({ children, className, ...props }) => {
    return (
      <h2
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.h2} ${classNames(props)} ${className}`}
      >
        {children}
      </h2>
    )
  },
  H3: ({ children, className, ...props }) => {
    return (
      <h3
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.h3} ${classNames(props)} ${className}`}
      >
        {children}
      </h3>
    )
  },
  H4: ({ children, className, ...props }) => {
    return (
      <h4
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.h4} ${classNames(props)} ${className}`}
      >
        {children}
      </h4>
    )
  },
  H5: ({ children, className, ...props }) => {
    return (
      <h5
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.h5} ${classNames(props)} ${className}`}
      >
        {children}
      </h5>
    )
  },
  H6: ({ children, className, ...props }) => {
    return (
      <h6
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.h6} ${classNames(props)} ${className}`}
      >
        {children}
      </h6>
    )
  },
  P: ({ children, className, ...props }) => {
    return (
      <p
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.p} ${classNames(props)} ${className}`}
      >
        {children}
      </p>
    )
  },
  Subtitle: ({ children, className, tag = "p", ...props }) => {
    return (
      <p
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.subtitle} ${classNames(props)} ${className}`}
      >
        {children}
      </p>
    )
  },
  Heading: ({ children, className, tag = "h4", ...props }) => {
    return (
      <h4
        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        className={`${styles.heading} ${classNames(props)} ${className}`}
      >
        {children}
      </h4>
    )
  },
}

function classNames(props) {
  return Object.keys({ ...props })
    .map(key => (props[key] ? styles[key] || "" : ""))
    .join(" ")
}

export default Text
