import React from "react"
import FAQ from "../../components/FAQ"

import * as styles from "./FAQs.module.scss"
import Row from "../../components/Row/Row"

const FAQs = () => {
  const [faqs, setFaqs] = React.useState([
    {
      question: "How does the Canonic Affiliate Program work?",
      answer:
        "The Canonic Affiliate Program allows you to earn commissions by referring customers to our low-code platform. You can fill out the registration form to get a unique referral link. When someone signs up for Canonic through this link and becomes a paying customer, you’ll be notified via email and receive a commission at the end of the month.",
      open: false,
    },
    {
      question: "Is there a cost to join the affiliate program?",
      answer:
        "No, joining the Canonic Affiliate Program is completely free. You can start earning commissions without any upfront investment.",
      open: false,
    },
    {
      question: "How are commissions paid?",
      answer:
        "Commissions are paid regularly, typically monthly. We offer multiple payment options, via Stripe.",
      open: false,
    },
    {
      question:
        "Can I participate in the program if I'm not a Canonic customer?",
      answer:
        "Absolutely! You don't need to be a Canonic customer to join our affiliate program. However, familiarity with our platform will help you promote it more effectively.",
      open: false,
    },
    {
      question: "Are there any restrictions on promotional methods?",
      answer:
        "While we encourage creativity, we expect our affiliates to promote Canonic ethically and responsibly.",
      open: false,
    },
  ])

  const toggleFAQ = React.useCallback(index => {
    setFaqs(faqs =>
      faqs.map((faq, i) =>
        i === index ? { ...faq, open: !faq.open } : { ...faq, open: false }
      )
    )
  }, [])

  return (
    <Row className={styles.faqRow}>
      <h2 className={styles.faqRowTitle}>FAQs</h2>
      <div className={styles.faqs}>
        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </Row>
  )
}

export default FAQs
